import { Button } from "@chakra-ui/react";
import styles from "../../styles/Components.module.css";

const SubmitButton = ({
  title,
  backgroundColor,
  fontColor = "white",
  margin = "0",
  size = "lg",
  maxWidth = "16rem",
}) => {
  return (
    <Button
      className={styles.button}
      style={{
        backgroundColor: backgroundColor,
        margin: margin,
      }}
      size={size}
      rounded={3}
      fontSize={16}
      maxW={maxWidth}
      color={fontColor}
      type="submit"
    >
      {title}
    </Button>
  );
};

export default SubmitButton;
