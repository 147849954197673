import { useState } from "react";
import {
  Flex,
  FormControl,
  Input,
  Text,
  HStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import SubmitButton from "../SubmitButton";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  const onSubscribe = () => {
    axios
      .post("/subscribe", {
        email: email,
        subscribe: true,
      })
      .then(({ data }) => {
        alert(data);
        setEmail("");
      })
      .catch((e) => alert(e.message));
  };

  return (
    <form onSubmit={onSubscribe}>
      <FormControl isRequired>
        <Flex
          direction="column"
          background="#006067"
          p={isLargerThan500 ? 20 : 6}
        >
          <Text
            fontSize={32}
            fontWeight="900"
            color="white"
          >
            Subscribe to our Newsletter
          </Text>
          <Text
            fontSize={18}
            fontWeight="200"
            color="white"
            maxW={700}
          >
            Sign up to our newsletter to receive occasional updates including
            new features, articles and communications. You can opt out at any
            time.
          </Text>

          <HStack my={5}>
            <Input
              background="whiteAlpha.900"
              maxW={400}
              fontWeight="200"
              type="email"
              rounded={3}
              placeholder="Enter your email address"
              color="black"
              _placeholder={{ color: "gray" }}
              _focusVisible={{}}
              value={email}
              isRequired
              onChange={(e) => setEmail(e.target.value)}
            />
            <SubmitButton
              backgroundColor="#cf0032"
              fontColor="white"
              title="Subscribe"
              margin="0px 10px"
              size="md"
            />
          </HStack>
        </Flex>
      </FormControl>
    </form>
  );
};

export default Subscribe;
